import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HeroBgImage from "../../components/logistic-park/index/hero-image"
import Company from "../../components/logistic-park/index/company"


const IndexPage = ({ location }) => (
    <Layout location={location}>
        <Seo title="Home" />
        <HeroBgImage />
        <Company />
    </Layout>
)

export default IndexPage
 